<template>
  <div class="overflow-x-auto">
    <table class="table w-full table-compact">
      <thead>
        <tr>
          <th>Exchange/Symbol</th>
          <th>Price</th>
          <th>Index Price</th>
          <th>Basic</th>
          <th>Vwap</th>
          <th>Vwap Rate</th>
          <th>FR</th>
          <th>Pre FR</th>
          <th>Yield</th>
          <th>Interest</th>
          <th>Volume(24h)</th>
          <th>Expiry</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, idx) of stats" :key="idx">
          <th :class="expireClasses(item)" data-label="Exchange/Symbol">
            <span class="badge"> {{ item.exchange }} </span>
            <span class="badge badge-info ml-2">
              {{ item.symbol }}
            </span>
          </th>
          <td
            class="table-stat-item"
            :class="expireClasses(item)"
            data-label="Last Price"
          >
            <div class="">
              {{ item.last | fixedTwo | formatNumber }}
            </div>
          </td>
          <td
            class="table-stat-item"
            :class="expireClasses(item)"
            data-label="Index Price"
          >
            <div>{{ item.index | fixedTwo | formatNumber }}</div>
          </td>
          <td
            class="table-stat-item"
            :class="expireClasses(item) + ' ' + basicColor(item.basic)"
            data-label="Basic"
          >
            <div>{{ item.basic | percentage4 }}</div>
          </td>
          <td
            class="table-stat-item"
            :class="expireClasses(item)"
            data-label="Vwap"
          >
            <div>{{ item.vwap | formatNumber }}</div>
          </td>
          <td
            class="table-stat-item"
            :class="expireClasses(item) + ' ' + basicColor(item.vwBasic)"
            data-label="Vwap Rate"
          >
            <div>{{ item.vwBasic | percentage4 }}</div>
          </td>
          <td
            class="table-stat-item"
            :class="expireClasses(item) + ' ' + fundingColor(item.rate)"
            data-label="FR"
          >
            <div>{{ item.rate | percentage4 }}</div>
          </td>
          <td
            class="table-stat-item"
            :class="expireClasses(item) + ' ' + fundingColor(item.preRate)"
            data-label="Pre FR"
          >
            <div>{{ item.preRate | percentage4 }}</div>
          </td>
          <td
            class="table-stat-item"
            :class="expireClasses(item) + ' ' + yieldColor(item.yield)"
            data-label="Yield"
          >
            <div>{{ item.yield | percentage2 }}</div>
          </td>
          <td
            class="table-stat-item"
            :class="expireClasses(item) + ' ' + interestColor(item.interest)"
            data-label="Interest"
          >
            <div>{{ item.interest | bigNumber }}</div>
          </td>
          <td
            class="table-stat-item"
            :class="expireClasses(item) + ' ' + volumeColor(item.volume)"
            data-label="Volume"
          >
            <div>{{ item.volume | bigNumber }}</div>
          </td>
          <td
            class="table-stat-item"
            :class="expireClasses(item)"
            data-label="Expriry"
          >
            <div>{{ item.expiry || '-' }}</div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { marketActions } from '@/store/constants.js'

const eps = 1e-6

function _validNumber(number) {
  if (typeof number !== 'number' || Math.abs(number) < eps) {
    return false
  }
  return true
}

export default {
  name: 'market-information',

  meta: {
    title: 'Stats',
  },

  mounted() {
    this.$store.dispatch(`market/${marketActions.SYNC_STATS}`)
    if (process.env.NODE_ENV !== 'development') {
      setInterval(() => {
        this.$store.dispatch(`market/${marketActions.SYNC_STATS}`)
      }, 5e3)
    }
  },

  computed: {
    ...mapGetters({
      stats: 'market/stats',
    }),
  },

  methods: {
    _colorLevel(val, l2, l3) {
      val = Math.abs(val)
      return val > l3 ? 3 : val > l2 ? 2 : 1
    },
    expireClasses(item) {
      return item.expiry ? 'expire' : ''
    },
    basicColor(val) {
      if (!_validNumber(val)) {
        return ''
      }
      if (Math.abs(val) < 1e-4) {
        return ''
      }
      const level = this._colorLevel(val, 1e-4, 1e-3)
      return val > 0 ? `green-${level}` : `red-${level}`
    },
    fundingColor(val) {
      if (!_validNumber(val)) {
        return ''
      }
      const level = this._colorLevel(val, 1e-4, 2e-4)
      return val > 0 ? `green-${level}` : `red-${level}`
    },
    yieldColor(val) {
      if (!_validNumber(val)) {
        return ''
      }
      const level = this._colorLevel(val, 3e-2, 6e-2)
      return val > 0 ? `green-${level}` : `red-${level}`
    },
    interestColor(val) {
      return val > 1e4 ? 'yellow-' + this._colorLevel(val, 1e6, 1e8) : ''
    },
    volumeColor(val) {
      return val > 1e5 ? 'yellow-' + this._colorLevel(val, 1e6, 1e9) : ''
    },
  },
}
</script>

<style lang="scss" scoped>
.table-stat-item {
  padding: 0.2rem !important;
  text-align: center;
  @apply font-medium;
}

.expire {
  background: #b5caea !important;
}

.yellow {
  &-1 {
    background: #fffce1;
  }

  &-2 {
    background: #ffffbf;
  }

  &-3 {
    background: #fbfb3e;
  }
}

.red {
  &-1 {
    background: #ffe8e6;
  }

  &-2 {
    background: #f7978f;
  }

  &-3 {
    background: #f55a4d;
  }
}

.green {
  &-1 {
    background: #e1fff0;
  }

  &-2 {
    background: #abffcb;
  }

  &-3 {
    background: #25ff7a;
  }
}
</style>
